/* ----------------------------------------- */


/* Content Template: template-for-atm - start */


/* ----------------------------------------- */

.product-detail-img {
    max-height: 570px;
}

.specs-desc p {
    width: 100%;
}

.contact-sales {
    display: block;
    width: 1100px;
    margin: 0 auto;
}

.contact-sales a {
    color: white;
    font-weight: bold;
    font-size: 20px;
    position: absolute;
    margin-top: -90px;
    margin-left: 0px;
    text-decoration: underline;
}

.atm-text {
    padding: 5rem 0 2rem;
    max-width: 1100px;
    margin: 0 auto;
}

.atm-text-full strong {
    font-size: 20px !important;
}

.l-content section.l-section {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.l-content .l-section-h.i-cf {
    max-width: 100%;
    padding: 0px !important;
}

.team-content {
    padding: 5rem 0 2rem;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}

.team-text,
.team-photo {
    display: flex;
    flex-direction: column;
}

.team-text {
    width: 65%;
    min-height: 500px;
}

.team-photo {
    width: 35%;
    margin-top: -35px;
    height: 570px;
    margin-bottom: -80px;
}

@media only screen and (min-width: 1570px) {
    .team-text {
        width: 65%;
        min-height: 600px;
    }
    /* .team-content, .l-subheader-h { max-width: 1400px; }*/
    .team-photo {
        min-height: 570px;
        max-height: 650px;
        height: unset;
    }
    .content-text {
        width: 80% !important;
    }
}

.team-wrap {
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.79) 2%, rgba(0, 0, 0, 0.45) 22%, rgba(46, 46, 46, 0.37) 27%, rgba(182, 182, 182, 0.14) 42%, rgba(237, 237, 237, 0.05) 48%, rgba(255, 255, 255, 0.06) 50%, rgba(255, 255, 255, 0.09) 55%, rgba(159, 159, 159, 0.18) 72%, rgba(0, 0, 0, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.79) 2%, rgba(0, 0, 0, 0.45) 22%, rgba(46, 46, 46, 0.37) 27%, rgba(182, 182, 182, 0.14) 42%, rgba(237, 237, 237, 0.05) 48%, rgba(255, 255, 255, 0.06) 50%, rgba(255, 255, 255, 0.09) 55%, rgba(159, 159, 159, 0.18) 72%, rgba(0, 0, 0, 1) 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.79) 2%, rgba(0, 0, 0, 0.45) 22%, rgba(46, 46, 46, 0.37) 27%, rgba(182, 182, 182, 0.14) 42%, rgba(237, 237, 237, 0.05) 48%, rgba(255, 255, 255, 0.06) 50%, rgba(255, 255, 255, 0.09) 55%, rgba(159, 159, 159, 0.18) 72%, rgba(0, 0, 0, 1) 100%);
    /*filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#c9000000', endColorstr='#000000', GradientType=1);*/
}

.inner-team-wrap {
    background: url(/photos/accent-atms-single-min.png)no-repeat;
    background-position: bottom;
    background-size: contain;
}

.content-title {
    font-family: 'Roboto', sans-serif;
    color: white;
    font-weight: 700;
    font-size: 610%;
    line-height: 90px;
    padding: 0;
}

span.cont-sep {
    display: block;
    width: 120px;
    border: 2.3px solid #00448F;
    border-radius: 10px;
    margin-bottom: 1.5rem;
    height: 0px !important;
    background: #00448F;
}

.sub-content-title {
    font-family: 'Rajdhani', sans-serif;
    color: white;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 2px;
    line-height: normal;
    margin-bottom: 10px;
    display: -webkit-inline-box;
}

.w-btn-wrapper.width_auto.align_left {
    font-size: 20px;
    margin: 2rem 0;
}

.content-text {
    margin-top: 1.5rem;
    color: #ffffff;
    font-size: 18px;
    line-height: 150%;
    font-weight: 300 !important;
    width: 97%;
    padding-bottom: 20px;
}

@media only screen and (max-width: 1125px) {
    .team-text {
        padding: 1.5rem;
    }
}

@media only screen and (max-width: 770px) {
    .team-text {
        width: 75%;
    }
    .team-wrap {
        background: rgba(0, 0, 0, 0.5);
    }
}

@media only screen and (max-width: 450px) {
    .team-text {
        width: 100%;
    }
    .content-text {
        width: 100%;
    }
    .team-content {
        padding: 1.5rem 0 2rem;
    }
    .content-text {
        font-size: 16px;
    }
}

.team-wrap-1 {
    background: #00448f;
}

@media only screen and (max-width: 770px) {
    .team-text {
        min-height: 300px;
    }
    .team-content {
        flex-direction: column-reverse;
    }
    .team-text,
    .team-photo {
        display: flex;
        width: 100% !important;
        flex-direction: column;
        margin: 0px !important;
    }
    .team-photo {
        background-position: left !important;
        height: 450px;
    }
    .inner-team-wrap {
        padding: 20px !important;
    }
    .team-text {
        padding: 2rem 0 !important;
    }
    .inner-team-wrap:after {
        display: block;
        width: 100%;
        height: 10px;
        background: white !important;
        content: " ";
        position: absolute;
        left: 0;
        z-index: 99;
        margin-bottom: -10px !important;
        margin-top: 19px;
    }
}

@media only screen and (max-width: 450px) {
    .sub-content-title br {
        display: none !important;
    }
}

@media only screen and (max-width: 1100px) {
    .contact-sales a {
        padding-left: 1.5rem;
        margin-top: -70px;
    }
    .atm-text {
        padding: 2.5rem 1.5rem;
    }
}

a.view-all {
    text-align: center;
    margin: 0 auto;
    font-weight: bold;
    font-size: 16px;
    color: #00448f;
    margin-bottom: 20px;
    DISPLAY: block;
}

a.btn-bottom {
    font-weight: bold;
    font-size: 20px;
    display: -webkit-inline-box;
    margin: 7px 0;
    color: #00448f;
}

.atm-text-full {
    margin-bottom: 5vh;
}

p.specs {
    font-size: 40px;
    font-weight: bold;
    display: block;
    margin: 50px 0 10px;
    color: #333333;
}

span.sep {
    border: 2px solid #333333;
    height: 0px;
    display: block;
    border-radius: 10px;
    background: #333333;
    margin: 30px 0 10px;
}

.specs-single {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}

.specs-name {
    min-width: 180px;
    width: 20%;
}

.specs-desc {
    width: 80%;
}

.specs-name,
.specs-desc {
    display: flex;
    flex-wrap: wrap;
}

.spec-wrap .specs-single:nth-child(even) {
    background: #f2fbf5;
    border-top: 1px solid #bde8cc;
    border-bottom: 1px solid #bde8cc;
}

@media only screen and (max-width: 770px) {
    .specs-desc {
        width: 100%;
    }
    .specs-name {
        font-weight: bold;
    }
}

@media only screen and (max-width: 820px) {
    .contact-sales a {
        padding-left: 0rem;
        margin-top: -70px;
    }
}

@media only screen and (max-width: 820px) and (max-height: 500px) {
    .contact-sales a {
        padding-left: 1.5rem;
        margin-top: -80px;
    }
}

@media only screen and (max-width: 450px) {
    .contact-sales a {
        font-size: 16px;
        padding-left: 0;
        margin-top: -50px;
    }
    .content-title {
        font-size: 510%;
    }
}

@media only screen and (max-width: 699px) {
    h1.content-title {
        font-size: 400% !important;
        line-height: 110% !important;
    }
}


/* ----------------------------------------- */


/* Content Template: template-for-atm - end */


/* ----------------------------------------- */