.section-products {
    .product-blk {
        border: 1px solid #eaeaea;
        padding: 30px 15px 15px 15px;
        text-align: center;
        background: #F7F7F7;
        /*box-shadow: 0px 0px 4px 4px #f2f2f2;*/
    }
    .product-img {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .product-img img {
        max-width: 100%;
        max-height: 300px;
    }
    .inquire-btn {
        padding: 5px 14px;
        font-size: 14px;
        @media screen and (max-width: 991px) {
            padding: 5px 10px;
        }
        border: none;
        border-radius: 5px;
    }
    .inquire-btn:hover {
        background-color: #f9853a;
        text-decoration: none;
    }
    .view-btn {
        font-size: 16px;
        color: #404040;
        background: transparent;
        padding: 10px 15px 10px 11px;
        border: none;
    }
    .download-btn {
        position: absolute;
        top: 1px;
        right: 16px;
        background: #FFF;
        padding: 2px 5px 5px 10px;
    }
    .download-btn img {
        width: 16px;
        height: 16px;
    }
    .download-btn span.download-brochure {
        color: #000;
        font-size: 15px;
        padding: 5px;
    }
    .product-blk .product-btn-blk .download-btn {
        display: none;
    }
    .product-blk:hover>.product-btn-blk>.download-btn {
        display: inline-block;
    }
}

.bgshape {
    background: url(../assets/images/organic_shape.svg) no-repeat 12vw calc(100vh - 640px);
}