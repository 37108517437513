@font-face {
    font-family: 'SF-pro-heavy';
    src: url('SFProText-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'SF-pro-semibold';
    src: url('SFProText-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SF-pro-medium';
    src: url('SFProDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SF-pro-light';
    src: url('SFProDisplay-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'SF-pro-bolditalic';
    src: url('SFProDisplay-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'SF-pro-ultralight';
    src: url('SFProDisplay-Ultralight.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'SF-pro-bold';
    src: url('SFProText-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SF-pro-light';
    src: url('SFProText-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'SF-pro-regular';
    src: url('SFProDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SF-pro-medium';
    src: url('SFProText-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}