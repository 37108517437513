body,
html {
    height: 100%;
    width: 100%;
    font-family: SF-pro-regular !important;
    color: #404040 !important;
    letter-spacing: 0.02em;
}

* {
    margin: 0;
    padding: 0;
}

.f-14 {
    font-size: 14px;
}

.f-20 {
    font-size: 20px;
}

.f-18 {
    font-size: 18px;
}

@media screen and (max-width: 576px) {
    .f-18 {
        font-size: 16px;
    }
}

.f-28 {
    font-size: 28px;
}

.f-36 {
    font-size: 36px;
}

@media screen and (max-width: 576px) {
    h2,
    h3 {
        font-size: 26px !important;
    }
}

.bg-orange {
    background-color: #fd9450;
}

.bg-green {
    background-color: #67a07d;
}

.bg-card-dark {
    background-color: #252B37;
}

button {
    outline: none !important;
}

button:focus {
    box-shadow: none !important;
}

.text-gray {
    color: #404040;
}

.txt-grn {
    color: #67a07d;
}

.section-faq,
.section-contact-us,
.section-products {
    padding-top: 100 px !important;
}