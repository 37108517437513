/*Banner Start*/

.section-banner {
    @media screen and (max-width: 991px) {
        padding-top: 100px;
    }
    .banner-left {
        .carousel-indicators {
            bottom: -100px;
            left: -91% !important;
            li {
                height: 10px;
                width: 10px;
                border-radius: 100%;
                background-color: #fd9450;
            }
        }
        h1 {
            font-family: SF-pro-bold;
            border-bottom: 1px solid #67a07d;
            color: #000000;
        }
        .carousel-item h1 {
            font-family: SF-pro-regular;
        }
        button:focus {
            border: none;
            box-shadow: none;
        }
        .inquire-btn {
            padding: 10px 40px;
            @media screen and (max-width: 991px) {
                padding: 5px 10px;
            }
            border: none;
            border-radius: 5px;
        }
        .inquire-btn:hover {
            background-color: #f9853a;
            text-decoration: none;
        }
        .view-btn {
            color: #404040;
            background: transparent;
            padding: 10px 40px;
            border: none;
            @media screen and (max-width: 991px) {
                padding: 5px 10px;
            }
        }
    }
}


/*Banner End*/


/*Payment Process Start*/

.section-pay-process {
    background: #f6f8f5;
    .services-blocks {
        .service-card-body {
            position: relative;
            height: 400px;
            max-height: 400px;
            border-radius: 20px;
            .atm-overlay {
                height: 400px;
                max-height: 400px;
                border-radius: 20px;
                background: rgba(0, 0, 0, 0.5);
            }
            .service-card-content {
                position: absolute;
                top: 50%;
                left: 50%;
                white-space: nowrap;
                transform: translate(-50%, -50%);
                z-index: 2;
            }
        }
        .bg-one {
            background-image: url("../assets/images/servicesImgs/atm-install-new-1.png");
            background-size: cover;
            background-position: 80%;
            background-repeat: no-repeat;
        }
        .bg-two {
            background-image: url("../assets/images/servicesImgs/atm-process.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        .bg-three {
            background-image: url("../assets/images/servicesImgs/atm-repairs.png");
            background-size: cover;
            background-position: 70%;
            background-repeat: no-repeat;
        }
        .bg-four {
            background-image: url("../assets/images/servicesImgs/credit-card.png");
            background-size: cover;
            background-position: 40%;
            background-repeat: no-repeat;
        }
        .bg-five {
            background-image: url("../assets/images/servicesImgs/and-more.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        .bg-six {
            background-image: url("../assets/images/servicesImgs/atm-install.png");
            background-size: cover;
            background-position: 80%;
            background-repeat: no-repeat;
        }
        .tabs {
            width: 100%;
            text-align: center;
        }
        .tabs .tab {
            background-color: #FFF;
            border: 0px;
            padding: 10px 25px;
            margin: 0px 10px;
            letter-spacing: 0.02em;
            margin-bottom: 15px;
        }
        .tabs .tab.active {
            background-color: #fd9450;
            color: #FFF;
            position: relative;
        }
        .tabs .tab.active .arrow-down {
            height: 0px;
            width: 0px;
            border: none;
            border-top: 15px solid #fd9450;
            border-left: 15px solid rgba(0, 0, 0, 0);
            border-right: 15px solid rgba(0, 0, 0, 0);
            position: absolute;
            bottom: -14px;
            left: 45%;
        }
        .tabs .content {
            background-color: #FFF;
            padding: 30px;
            box-shadow: 0px 2px 5px 2px #f2f2f2;
        }
        @media screen and (max-width: 991px) {
            .tabs .tabHeader {
                width: 100%;
                overflow: scroll;
            }
            .tabs .tabHeader .tabHeaderScroll {
                width: 1140px;
            }
        }
    }
}


/*Payment Process End*/


/*Grow Business Start*/

.section-grow-business {
    border-bottom: 1px solid #eaece7;
    .grow-left {
        .we-focused {
            position: relative;
            padding-left: 150px;
            &::before {
                content: "";
                position: absolute;
                height: 1px;
                width: 140px;
                background: #67a07d;
                left: 0;
                top: 10px;
            }
        }
    }
}


/*Grow Business End*/


/**/

.section-choose-liberty {
    .liberty-blocks {
        p {
            font-family: SF-pro-semibold;
        }
    }
}


/**/


/*Footer Start*/

.section-footer {
    background-color: #f7f7f7;
    input {
        border-radius: 0;
        box-shadow: none;
        background-color: #eaece7;
        &:focus {
            box-shadow: none;
            border: #eaece7;
        }
    }
    button {
        border-radius: 0;
        box-shadow: none;
        background-color: #67a07d;
        box-shadow: none;
        outline: none;
        &:focus {
            box-shadow: none;
        }
    }
    .copy-right {
        border-top: 1px solid #eaece7;
    }
    a {
        color: #404040;
        text-decoration: none;
    }
    a:hover {
        color: #999;
        text-decoration: none;
    }
}


/*Footer End*/