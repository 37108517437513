html {
    scroll-behavior: smooth;
}

a:hover {
    text-decoration: none !important;
}

.section-header {
    border-bottom: 1px solid #eaece7;
    @media screen and (max-width: 991px) {
        position: absolute;
        top: 0;
        background: #f6f8f5;
        width: 100%;
        z-index: 1;
    }
    .navigation-bar {
        .logo {
            width: 314px;
            @media screen and (max-width: 991px) {
                width: 185px;
            }
        }
        li {
            margin: 0 10px !important;
            a {
                padding: 15px 12px !important;
                color: #000000;
                font-family: SF-pro-semibold;
                white-space: nowrap;
            }
        }
        .drop-arrow {
            background-image: url("../assets//images/arrow-down.svg");
            background-repeat: no-repeat;
            background-position: 98%;
        }
        .active {
            border-bottom: 2px solid #67a07d;
        }
        button {
            border: none;
            outline: none;
        }
        .call-btn-main {
            border-radius: 5px;
            min-width: 185px;
            .call-btn {
                padding: 5px 10px;
                font-size: 16px;
                line-height: 1.3em;
                background: url("../assets/images/phone-call.svg") no-repeat top right;
                color: #ffffff;
                span {
                    font-size: 14px;
                }
            }
        }
    }
}